import React from 'react';
import classnames from 'classnames';

import Markdown from 'src/components/design-system/markdown';

const Nouvelle = ({
  className,
  author,
  created_at,
  title,
  body,
  ...otherProps
}) => (
  <div className={classnames('', className)} {...otherProps}>
    <p className="text-sm text-gray-400">
      Par <span className="text-gray-500">{author}</span> le{' '}
      <span className="text-gray-500">{created_at}</span>
    </p>
    <h3 className="text-lg text-gray-900">{title}</h3>
    <Markdown>{body}</Markdown>
    <hr className="w-1/3 my-8 border border-gray-200 m-auto" />
  </div>
);

export default Nouvelle;
