import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Nouvelle from './nouvelle';

const Nouvelles = () => {
  const {
    allNouvelles: { edges },
  } = useStaticQuery(graphql`
    {
      allNouvelles {
        edges {
          node {
            data {
              title
              body
              id
              created_at
              updated_at
              author {
                name
              }
            }
          }
        }
      }
    }
  `);

  const nouvelles = edges[0].node.data
    .map((e) => ({
      ...e,
      author: e.author.name,
    }))
    .reverse();

  return (
    <div>
      {nouvelles.map((n) => (
        <Nouvelle {...n}></Nouvelle>
      ))}
    </div>
  );
};

export default Nouvelles;
